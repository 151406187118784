// Search form and results

// Live results
// -------------------------
    .pat-livesearch .livesearch-results {
        z-index: $zindex-navbar + 1 !important;
        .results-summary {
            margin: 1em 0;
            color: $gray-light-medium;
        }
        li {
            padding: 0 1em;
        }
        h4 {
            margin-bottom: 0;
        }
    }

// Search form
// --------------------------
    #searchGadget_form {
        input.bg-gray-lighter {
            background-color: $gray-lighter;
            height: $input-height-base;
        }
    }

// Search results
// ----------------------------
    .searchResults {
        .result-title {
            @extend .h4;
        }
    }
    #content-core .autotoc-nav {
        a {
            border-bottom: 0 solid transparent !important;
        }
    }
    .search-results-wrapper {
        margin-top: 3.0rem;
    }
    .searchPage {
        .documentFirstHeading {
            margin-top: 6.6rem;
        }
    }
    #search-results li {
        padding-bottom: 1.2rem;
    }
    #search-filter-toggle {
        padding: 0.8rem;
        background-color: $gray-lighter;
        &:after {
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 8px 4px 0 4px;
            border-color: #666666 transparent transparent transparent;
            display: inline-block;
            content: '';
            margin-left: 0.5rem;
        }
    }
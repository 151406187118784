// Document Actions: print, send, social share...

// Actions buttons
    .document-action {
        a {
            transition: color .25s ease;
            &:hover {
                color: $brand-light;
                text-decoration: none;
                
            }
        }
      
    }
  
// Navbar toggle
//
// Custom button for toggling the `.navbar-collapse`, powered by the collapse
// JavaScript plugin.

.navbar-toggle {
  
  .menu-bar {
    // margin-top: 1.25rem;
    &:before, &:after {
      content: ' ';
    }
    &:before {
      top: 0;
      transform: rotate(45deg);
    }
    &:after {
      bottom:0;
      transform: rotate(-45deg);
    }    
  }
  .icon-bar + .icon-bar {
    margin-top: 4px;
  }
  &.collapsed {
    .menu-bar:before, .menu-bar:after {
      transform: rotate(0);
    }
    .menu-bar::before {
      top: -0.8rem;
    }
    .menu-bar:after {
      bottom: -0.8rem;
    }
  }
  &:focus {
    background-color: transparent;
  }

}

// Darken the responsive nav toggle
	.navbar-toggle {
		&.collapsed .menu-bar, .menu-bar:before, .menu-bar:after {
			background-color: $navbar-inverse-toggle-icon-bar-bg;
		}
		.menu-bar {
			background-color: transparent;
		}
	}
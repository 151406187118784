// Classes related with images non-suitable for bootsrap

// Cover element with image
.bgimage {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    display: block;
    height: 20.0rem;
    width: 100%;
    overflow: hidden;
}

// Image width a dark effect 
.dark-bg {
    position: relative;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-color: rgba($gray-base, 0.75);
    background-blend-mode: soft-light;
    .ie &:after {
        content:'';
        display: block;
        top:0; right: 0; bottom: 0; left: 0;
        opacity: .75;
        background-color: rgba($gray-base, 0.75); 
    }
    &--20 {
        background-color: rgba($gray-base, 0.20);
    }
}

// Standard website image
    .brand-bg {
        background-image: url('../images/img-background-premia.jpg');
    }

// Object fit image 
.objectFit {        
    max-width: 100%;
    width: 100%;                
    @supports (object-fit: cover) {
        max-width: none;
        object-fit: cover;
        object-position: center center;   
        height: 100%;
    }
}
// Plone specific
@import "block-grid";
@import "blog";
@import "borders";
@import "buttons";
@import "calendar";
@import "document-actions";
@import "footer";
@import "full-view";
@import "grid";
@import "highcontrast";
@import "images";
@import "listing-view";
@import "navbar";
@import "position";
@import "responsive-utilities";
@import "scaffolding";
@import "search";
@import "spacing";
@import "summary-view";
@import "type";
@import "utilities";


.themeEntryControls button[type="submit"] {
  border: $btn-default-border;
}
input[type="submit"] {
  border: $btn-default-border;
}
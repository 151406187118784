// Classses for headings and text
  .font-text {
    font-family: $font-family-sans-serif;
  }
  .font-heading {
    font-family: $font-family-serif;
  }

  .h1-size { font-size: $font-size-h1; }
  .h2-size { font-size: $font-size-h2; }
  .h3-size { font-size: $font-size-h3; }
  .h4-size { font-size: $font-size-h4; }
  .h5-size { font-size: $font-size-h5; }
  .h6-size { font-size: $font-size-h6; }

// Alignment
  @mixin set-alignment ($query:'') {
    .text#{$query}-left           { text-align: left; }
    .text#{$query}-right          { text-align: right; }
    .text#{$query}-center         { text-align: center; }
    .text#{$query}-justify        { text-align: justify; }
    .text#{$query}-nowrap         { white-space: nowrap; }
  }
  // ==== Screen independent  
          @include set-alignment('');
          
  // ==== Extra small screen / phone
      @media screen and (max-width: $screen-xs-max) {
           @include set-alignment('-xs');   
      }
  // ==== Small screen / tablet 
      @media screen and (min-width: $screen-sm-min) {
          @include set-alignment('-sm');
      }
  // ==== Medium screen / desktop
      @media screen and (min-width: $screen-md-min) {
           @include set-alignment('-md'); 
      }
  // ==== Large screen / wide desktop    
       @media screen and (min-width: $screen-lg-min) {
           @include set-alignment('-lg');
      }

// style
    .text-normal         	{ font-style: normal; }    
    .text-italic          { font-style: italic; }
    .text-regular         { font-weight: $font-regular;}
    .text-semi            { font-weight: $font-semi;}
    .text-bold            { font-weight: $font-bold; }

// Contextual colors
  
  @include text-emphasis-variant('.text-white', $white);
  
  @include text-emphasis-variant('.text-darker', $gray-darker);
  
  @include text-emphasis-variant('.text-gray', $gray);
  
  @include text-emphasis-variant('.text-gray-light', $gray-light);
  
  @include text-emphasis-variant('.text-gray-light-medium', $gray-light-medium);

  @include text-emphasis-variant('.text-secondary', $brand-secondary);
  
  @include bg-variant('.bg-white', $white);
  
  @include bg-variant('.bg-brand-light', $brand-light);
  
  @include bg-variant('.bg-brand-gray', $brand-gray);
  
  @include bg-variant('.bg-brand-dark', $brand-gray);
  
  @include bg-variant('.bg-black', $gray-base);
  
  @include bg-variant('.bg-gray', $gray);
  
  @include bg-variant('.bg-gray-light-medium', $gray-light-medium);
  
  @include bg-variant('.bg-gray-lighter', $gray-lighter);
  
  @include bg-variant('.bg-none', transparent);

// Headings
// -----------
    .bigHead {
        font: $font-bold italic 4.0rem/1.25em $font-family-serif;
        color: $white;
        text-shadow: 0 0 .7rem rgba($gray-base, .75);
        margin: 0 auto 0.25em auto;
        @media screen and (min-width: $screen-sm-min) {
            font-size: 6.0rem;
        }
    }
    .headline {
        a {
            color: inherit;
            &:hover {
                opacity: .8;
            }
        }
    }

// Content
// --------------------
    .documentDescription {
        text-transform: uppercase;
        font-size: 1.4rem;
        margin-bottom: 5.4rem;
        font-weight: $font-bold;
    }


    .no-underline {
        text-decoration: none;
        &:hover {
            text-decoration: none;
        }
    }

$ints: (0, 1, 2, 3, 4, 5);
$decis: (25, 50, 75);

@mixin font-sizes {
  @each $int in $ints {
    .fs-#{$int} { font-size: #{$int}em }
    .fsr-#{$int} { font-size: #{$int}rem }
    @each $deci in $decis {
      .fs-#{$int}-#{$deci} { font-size: #{$int + '.' + $deci}em }
      .fsr-#{$int}-#{$deci} { font-size: #{$int + '.' + $deci}rem }
    }
  }
}
@include font-sizes();
// .template-listing_view
.template-listing_view {
    #content-core {
        .entries {
            list-style-type: disc;
            padding-left: 1.5em;
            .entry {
                display: list-item;
            }
            .documentByLine {
                font-size: 80%;
                font-weight: $font-regular;            
                display: block;
            }
        }
        header {
            @extend .h4;
            a {
                color: inherit;
                border-bottom: none;
                &:hover {
                    opacity: .8;
                }
            }
        }
    }
}
.template-summary_view {
    #content-core {
        .tileItem {
            a.summary {
                border-bottom-color:transparent;
            }
            .documentByLine {
                display: block;
            }
        }
    }
}
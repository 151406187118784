/* #######################################
	TOC
	1 - BODY
	2 - HEADER
		2.0 - Header top
		2.1 - Search
		2.2 - Main menu
		2.3 - Announcement
		2.5 - Social Icons
		2.6 - AEMET
		2.7 - Breadcrumps
		2.8 - Translate
	3 - HOME
		3.1 - Slider
		3.3 - Icon links
		3.4 - News
		3.5 - Events
		3.6 - Banners
		3.7 - Teme
		3.8 - Xarxes socials
	4 - FOOTER
		4.1 - Banner ico
		4.2 - Footer Menu

	5 - OTHER
		5.1 PORTLET
		5.2 MODAL WINDOWS
		5.3 SOCIAL ICONS
		5.4 PAGINATION
		5.5 SEARCH
		5.6 OTROS

	6 - MINISITE

	#######################################    */


// #######################################

// #######################################

//1 - BODY

	.font-heading {
        font-family: $font-family-sans-serif;
    }
    .color-primary{
    	color:$brand-primary	;
    }
    .color-icon{
    	color:$icon_color;
    }
    .backgroundcolor-gray-light{
    	background-color: $gray-lighter;
    }

	tr,table,td,thead,tbody, th {
		&:after, &:before {
			content: none;
		}
	}



// 2 - HEADER

/*#fondo-descripcion{
		display: none;
}*/


	#header_fondo{

			&::before {
				content: '';
				display: block;
				top: 0;right: 0;bottom: 0;left: 0;
				background-color: $gray-darker;
				opacity: .43;
				position: absolute;
				z-index: 0;
			}
		}

		/*#header_interior{
			position:absolute;
			z-index:-1;
			height: 300px;
			@media (max-width: $screen-xs-max) {
			    height: 280px;
			}


			margin:0px;
			padding:0px;
			//display: block;
			width: 100%;
			 filter: brightness(50%);
 			//opacity: 0.5;
   			//filter: alpha(opacity=50);
		}*/
		.homeLinks__icon{
			flex-shrink:0;

		}

// 2.0 - Header top
    #fondo-cabecera{
			background-color: rgba(0, 0, 0, .5);
		}
	#pre-header{
		a {
			color: $white;
			&:hover, &:focus {
				color: lighten($brand-primary,15%);
			}
		}
	}
	body.template-portada-view #actions-home a,
	body.template-contact-info #actions-contact a {
		color: $brand-primary;
	}


	/* mnp Menú superior*/
	#site-actions-top {
		display: flex;
	}

 	#cabecera.affix{
		.portalLogo__image {
			width: 16.0rem; height: auto;
		}
 		//cambio el color del fondo de la cabecera
 		background-color: $back_color_opacity;
 		.button:active{
 			background-color: transparent;
		 }
		 #mainNavWrapper {
			 margin-top: 1.6rem !important;
		 }
 	}

 	.navbar-collapse.in ,.navbar.collapsing{

 		margin-top: 4rem;
 		margin-bottom: 4rem;
 		background-color: $gray-darker;
 		position: fixed;
 		width: 100%;
 		left: 0px;
 		z-index:10;
 		text-align: right;
 		 font-weight: bold;
 		 div div{
 		 	margin-top: 2px;
 		 	margin-bottom:2px;
 		 }
		@media (min-width: $screen-sm-min) { // 768 pixels - 48em
			margin-top: 0;
			margin-bottom: 0;
		}

 	}

 	.button.navbar-togle.collapsing{
 		background-color: transparent;

 	}
 .nav.navbar-nav{

 	padding-bottom: 3px;
 	font-weight: bold;
 }


#actions-highContrast{
	padding-top: 1.2rem;
}

#content-header .rounded-icon{
	background-color: transparent;
	&:hover,&:focus{
		background-color: transparent;
	}
}


// 2.1 - Search

	.LSBox{
		    align-items: center;
	    justify-content: center;
	}


	#headerSearch {
		input[type="text"] {
			width: auto;
			flex: 1 1 auto;
			min-width: 0;
		}

	}
	.pat-livesearch .livesearch-results {
		color: $text-color;
	}
	#collapseSearchBox{
		border: 2px solid white;
	}
	#portal-searchbox{
	    border: 1px white solid;

		//vertical-align: center;
		.icon-search{
			color: $white;
			&:hover,&:focus{
				color: $white;
			}
		}
	}

	/* para la portada el buscador*/
    .template-portada-view #portal-searchbox{
		@media (min-width: $screen-lg-min){
			margin-top: 180px;
			//margin-bottom: 250px;
		}
		@media (min-width: $screen-xs-min){
			margin-top: 100px;
			margin-bottom: 15px;
		}
	}

	.searchbox-header input {
		float:left;
	}


	#autotoc-nav{
		color:$gray-darker;
	}


	.modalSearch__dialog {
		margin-top:2rem!important;
		margin-bottom:2rem!important;

	}
	#searchGadget{
		margin-right: 0px;
		padding-right: 0px;

	}

	#headerSearch{

		  input{

			border:0px solid transparent;
			border-width: 0px;
			background-color: transparent;
			color:$white;
		}
	}
	#caja_buscador{
			margin-bottom:1rem;
		    border:2px solid white;
			background-color: transparent;
			color:$white;
			align-items: center;
			justify-content: center;

			display:flex;
			width: 100%;
			border-radius: .4rem;
			transition: background-color $transition-fast ease-in;
			::placeholder{
				color: rgba($white, 0.75);
			}
			&.inputActive {
				background-color: rgba($gray-base, .85);
			}


	}

    .template-portada-view.modalSearch__dialog{
		margin-top: 3rem;
		@media (min-width: $screen-md-max) {
		margin-top: 7.2rem;
		}
	}

	.template-portada-view #caja_buscador{
		margin-bottom: 10.0rem;
		@media (min-width: $screen-md-max) { // 768 pixels - 48em
			margin-top: 15.0rem;
			margin-bottom: 15rem;
		}

	}

	#headerSearch input[type="text"] {
		font-size: 2.0rem;
	    flex: 1 1 auto;
	    color:white;
	}
	#searchGadget{
		margin-right: 0px!important;
	}
   .searchButton{
   	margin-top: 7px;
   	background-color: transparent;
   	&:hover,&:focus{
   		//color:$white;
   		box-shadow: transparent;
		   border-color: transparent;
		   color: $brand-primary;
		   span {
			   color:$brand-primary;
		   }
   	}

   }

.standalone:hover, .link-parent:hover, [type="submit"]:hover, button:hover {

    background-color: transparent;
}

    input[type="text"]:focus{
	    border-color: transparent;
	    outline: 0;
	    box-shadow: inset 0 0px 0px transparent, 0 0 0px transparent;
	}
	.picto-search{
		font-size: $font-size-h3;
		color:$white;
		background-color: transparent;
		&:focus,&:hover{
			color:$icon_color;
			background-color: transparent;
		}
		margin-top:13px;
	}

	#searchform, #search-term{
		color:$gray-darker;
	}
	#searchform input[type="text"]:focus{
		box-shadow: inset 0 0px 0px $icon_color, 0 0 0px $icon_color;
	}

	// 2.2 - Main menu
		#menu_logo{
			max-width: none!important;
		}
		nav>li>a:focus, .nav>li>a:hover {
		    text-decoration: none;
		    background-color: $brand-primary;

		}
		#content-header{
			background: transparent;
		}
		.nav-logo {
			max-width: 256px;
		}
		.mainNav {
			font-size:$font-bold;// 2.0rem;
			&__list{
				&.collapse.in {
					display: flex;
				}
				flex-direction: column;
				&:after, &::before {
					content: none;
				}
				li {
					margin-bottom: 1.5rem;
				}
			}
			a {
				color: $white;//$gray-dark;
				overflow: hidden;
				position: relative;
				white-space: nowrap;
				span {
					position: relative;
					z-index: 1;
				}
				&::after {
					content: '';
					display: block;
					position: absolute;
					left: 0; right: 0;bottom: 0;top: 0;
					transform: scale(1 , 0);
					transform-origin: center bottom;
					transition: transform .35s ease-in;
					background-color: $brand-primary;
					z-index: 0;
				}
				&:hover, &:focus {
					text-decoration: none;

					&::after {
						transform: scale(1 , 1);
					}
				}
			}
			.active a {
				background-color: $brand-primary;
			}
			&:after {
				content: none;
			}
			.navbar-collapse.in, .collapsing {
				position: static;
				background-color: transparent;
			}
			@media (min-width: $screen-md-min) { // 1200 pixels - 75em
				position: static;
				&__list {
					flex-direction: row;
					justify-content: space-between;
					&.collapse {
						display: flex !important;
					}
					li {
						flex-grow: 1;
						margin-bottom: 0;
					}
				}
				&__link {
					transition: color $transition-fast ease-in;
					display: block;
					position: relative;
					padding: .9em 0;
					height: 100%;
					&:after {
						content: '';
						position: absolute;
						display: block;
						bottom: 0; left: 0; right: 0;
						height: .5rem;
						background-color: $brand-primary;
						transition: transform $transition-fast ease-in;
						transform: scale(0, 1);
						transform-origin: left center;
					}
					&:hover, &:focus, .currentItem &, .showNav & {
						color: $brand-primary;
						&:after {
							transform: scale(1, 1);
						}
					}
				}
				&__item.hasChildren {
					&:hover, &.showNav {
						.mainNav__subList {
							transform: translateX(0);
							&__wrapper {
								opacity: 1;
								left: 0;
							}
						}
					}
					& > a:focus + .mainNav__subList__wrapper {
						opacity: 1;
						left: 0;
						.mainNav__subList {
							transform: translateX(0);
						}
					}
				}
				&__subList {
					&__wrapper {
						position: absolute;
						left: -1000em;
						opacity: .33;
						transform:  opacity $transition-fast ease-in;
					}
					transform: translateX(1.6rem);
					transition: transform $transition-fast ease-in;
					display: flex;
					justify-content: flex-start;
					& > * {
						flex: 0 0 ((2 / 12) * 100%);
						padding: 1.2rem 1.6rem;
					}
				}
			}
		}
		.affix {
			box-shadow: 0 0 .4rem rgba($gray-base, .2);
			background-color: $back_color;
		}
		.affixHack {
			height: 12.5rem;
			display: none;
			.affix + & {
				display: block;
			}
		}

		// 2.5 - Social Icons

			.socialActionLink {
				font-size: 2.6rem;
				#portal-aemet-social-icons & {
					font-size: 3.2rem;
					color: $gray-light;
				}
				span[aria-hidden="true"]:before{
					transition: all $transition-fast ease-in;
				}
				&:hover, &:focus{
					color: $brand-primary !important;
					span[aria-hidden="true"]:before{
						transform: rotateY(360deg);
					}
				}
				#pre-header & .picto-youtube-circled {
					font-size: 2.4rem;
				}

			}


.socialShare__list{
		.facebook{
			background-color: #1f6aa1;
			border-radius:5px 0 0 5px ;
		}
		.twitter{
			background-color: #45a7ee;

		}
		.googleplus{
			background-color:#d9534f;
		}
		.whatsapp{
			background-color:#5cb85c;
		}
		.email{
			background-color:#f0ad4e;
			border-radius:0px 5px 5px 0px ;
		}

}
	.collapsing{
			background-color: transparent!important;
		}
	.socialShare {
		position: relative;
		z-index:1;
		.picto-facebook, .picto-twitter,.picto-gplus-1,.picto-whatsapp,.picto-mail{
			color: $white;
		}
		&__button:hover,&__button:focus {
				 color: $brand-primary;
				 text-decoration: underline;
		}
		.collapsing{
			background-color: transparent!important;
		}

		/*triangulo superior barra compartir*/
		&__list {
			position: absolute;
			left: 80%;
			//top: calc(100% + 0.5rem);
			transform: translateX(-50%);
			&:before{
				content: '';
				display: block;
				position: absolute;
				left:50%;
				top: -0.5rem;//mnp 2018_08_16
				width: 0;
				height: 0;
				transform: translateX(-50%);
				border-left: 7px solid transparent;
				border-right: 7px solid transparent;
				border-bottom: 7px solid #d9544f;
			}
		}
	}


		// 2.3 - Announcement
		#portal-announcements{
			.homeAnnouncements__link, .bxslider-announcements{
		  		color: $white;
			}
		}
		.homeAnnouncements__mainRow {
			max-width: 74.4rem;
			margin: 0 auto;
		}
		.bx-prev, .bx-next{
			color: $white;
			&:hover,&:focus{
				color:$gray-base;
				text-decoration: none;
			}

		}

		#portal-announcements .bx-wrapper{
			border:0px;
			position: relative;
		    margin-bottom: 0px;
		    padding: 0;
		    -ms-touch-action: pan-y;
		    touch-action: pan-y;
		    box-shadow: 0;
		    background: transparent;
		    box-shadow: 0 0 0px transparent;
		}


		// 2.6 - AEMET
			#portal-weather {
				color: $white;
				.image-aemet {
					height: 3.2rem; width: 3.2rem;
					opacity: .75;
					color: $white;
				}
			}


		// 2.7 - Breadcrumps


		#portal-breadcrumbs {
			text-transform: uppercase;
			font-size: $font-size-h6;
			a{
				color: $brand-primary;
			}
		}

		#breadcrumbs-current{
			color:$gray-dark;
			font-weight: $font-semi;
		}

// 2.8 - Translate
	#google_translate_element {
		.goog-te-gadget {
			font-family: $font-family-sans-serif;
		}
		.goog-te-gadget-simple {
			border: none;
			background-color: transparent;
			font-size: $font-size-h6;
		}
		.goog-te-gadget-icon {
			position: absolute;
			left: -1000rem;
		}
		.goog-te-menu-value {
			text-transform: none;
			img, *[aria-hidden="true"] {
				display: none;
			}
			span {
				border: none !important;
			}
			&:after {
				content: '\E837';
				font-family: "pictello";
				display: inline-block;
				font-size: 1.6rem;
				margin-left: .5rem;
			}
		}
	}

		// 3.1 - Slider
			.portalSlider {
				position: relative;
				&__content {
					min-height: calc(100vh - 17.0rem);
					display: flex;
					flex-direction: column;
					justify-content: center;
				}
				&__text {
					transition: transform $transition-fast ease-in 1s, opacity $transition-fast ease-in 1s;
					transform: translateY(-1.5rem);
					opacity: 0;
					.active & {
						transform: translateY(0);
						opacity: 1;
					}
				}
				&__title {
					font-size: $font-size-h2;
				}
				&__description {
					font-size: $font-size-h5;
				}
				&__image {
					position: absolute;
					left: 0;top: 0;bottom: 0;right: 0;
					&:before {
						content: '';
						position: absolute;
						left: 0;top: 0;bottom: 0;right: 0;
						background-color: $gray-base;
						opacity: 0.7;
					}
				}
				.objectFit {
					height: 100%;
				}
			}

// 3.3 - Icon links
	.homeLinks {
		&__item {
			margin-bottom: 3.6rem;
			&--theme {
				a:hover {
					.homeLinks__icon {
						background-color: $brand-primary;
						svg {
							fill: $white;
						}
					}
					color: $brand-primary;
					text-decoration: none;
				}
			}
			a {
				text-decoration: none;
				color: $gray;
				&:hover, &:focus {
					color: $brand-primary;
					text-decoration: none!important;
					.homeLinks__icon {
						background-color: $brand_primary;

						svg {
							fill: $white !important;
						}
					}
				}
			}
		}

		&__icon {
			transition: background-color $transition-fast ease-in;
			width: 14.0rem;
			height: 14.0rem;
			background-color: $white;
			border-radius: 50%;
			text-align: center;
			margin-left: auto;margin-right: auto;

			display: flex;
			svg {
				fill: $brand-primary;
				height: 8.9rem; width: auto;
				margin:auto;
			}
			&--themes {
				width: 7.8rem; height: 7.8rem;
				margin: 0 1.0rem 0 0;
				background-color: $gray-lighter;
				svg {
					height: 4.8rem;width: auto;
				}
			}
		}
	}
	@keyframes openDiv {
		0% 		{ transform: scale(1 , 0);}
		100% 	{ transform: scale(1 , 1);}
	}
	.reloadContent {
		animation: openDiv .5s ease-out;
		transform-origin: center top;
	}
	.get-more-items.text-white:hover {
		color: $gray-darker;
	}

    //mnp 2018_07_30

    .banner-item-title{
		text-decoration: none;

    	&:hover,&:focus{
    		text-decoration: none!important;
    		color: $brand-primary;
    	}
    }

    #homeLinks__item a:hover{
    	text-decoration: none!important;
    	color: $brand-primary;
    }

	// 3.4 - News

	.newsItem__title {
		color: $brand-primary;
	}
	.highContrast .newsItem__title {
		color: $high-contrast;
	}

	.documentDescription {
		text-transform: uppercase;
		color: #736b6b;
	}

	.newsItem{
		a {
			text-decoration: none;
		}
		&__image {
			height: 25.7rem;
		}
		.socialShare__button{
			background-color: transparent;
			color: $gray-darker;
		}
		&.bg-gray-lighter .socialShare__button{ background-color: transparent;}

		.itemNews{
			background-color: $gray-lighter;
		}
	}


	/*mnp 2018_08_02

	/* botón Veure més*/

		 .get-more-news , .get-more-news:visited, .get-more-items, .get-more-items:visited{
		 	border-radius: .4rem;
		 	background-color: $brand_primary;
		 	padding:1.3rem;
		 	&:hover,&:focus{
		 		color: $white;
		 		background-color: $gray-base;
		 	}
		 }




	//************************//
	// 3.5 - Events / Agenda
	//***********************//


		#home-events {
			background: url('++theme++ajsantfruitos/img/fons-agenda-xs.jpg') no-repeat center center / cover $gray-base;
			color: $white;
			@media (min-width: $screen-md-min) { // 992 pixels - 62em
				background-image: url('++theme++ajsantfruitos/img/fons-agenda-sm.jpg');
			}
			@media (min-width: $screen-lg-min) { // 1200 pixels - 75em
				background-image: url('++theme++ajsantfruitos/img/fons-agenda-lg.jpg');
			}


		}
		.btnEvents {
			transition: background-color $transition-fast ease-in;
			//background-color: $white;
			border-radius: 1.5em;
			color: $gray-darker;
			font-weight: $font-semi;
			padding: 0 1em;
			display: inline-block;
			& > * {
				display: inline-block;
				vertical-align: middle;
			}
			.active &, &:hover, &:focus {
				background-color: $brand-primary;
				color: $white;
			}
			&:hover, &:focus {
				background-color: $gray-darker;
				color: $white;
				text-decoration: none;
			}
		}

		.filterList {
			justify-content: flex-start;
			@supports (display: grid) {
				display: grid;
				/* autoprefixer: ignore next */
				grid-template-columns: repeat(auto-fit, minmax(20.0rem, 1fr));
				grid-gap: .2rem;
				.selectorEvents {
					margin: 0;
				}
			}
		}

		.selectorEvents {
			// min-width: 14.5rem;
			margin: 0 .2rem .2rem 0;
			transition: background-color $transition-fast ease-in;
			background-color: $gray-base; //$white;
			color: $white; //$gray-darker;
			font-weight: $font-semi;
			padding: .75em .5em;
			flex: 1 0 auto;


			@media (min-width: $screen-md-min) { //992px
				flex-basis: 20.0rem;
			}
		}

		.eventsItem {
			&__date {
				border: .2rem solid $white;
				width: 11.0rem; height: 11.0rem;
				border-radius: .6rem;
				display: flex; flex-direction: column; justify-content: center; align-items: center;
				flex: none;
				margin-right: 1.0rem;

			}

			&__subject {
				font-weight: $font-semi;
			}
			.socialShare__button{
				background-color: transparent;/* mnp 2018_08_16*/
				color:white;
				&:hover,&:focus{
					color: white;
					text-decoration: underline;
				}
			}


		}
		abbr[data-original-title], abbr[title] {
		    cursor: help;
		    border-bottom: 0px!important;
		}
		.event.summary{
			padding: 0px;
			margin-top: 4rem;
			max-width: 100%;
			box-shadow: 0;
			padding-bottom: 1rem;
			 a{

		    	border-bottom: 0px!important;
		    }
		    li{
		    	padding-left:2rem;
		    }
		    abbr[data-original-title], abbr[title] {
				    cursor: help;
				    border-bottom: 0px;
				}

		}

		//mnp 2018_08_01
		#datepicker table{
			color: $white;
			border: none;
			.datepicker-switch {
				color: $white;
			}
			thead {
				th {
					border: none;
					color: $white;
					text-align: center;
					&:hover,&:focus{
						background-color: transparent;
						color:$brand-primary;
					}
				}
				tr:last-child {
					border-bottom: .1rem solid $gray-light-medium;
				}
			}

			tbody tr{
				td[colspan="7"] {
					&:hover{
					color: white;
					background-color: transparent;
					}
					span{
					&:hover,&:focus{
						color:$brand-primary;
					}
				}
				}


			   border-color: $gray-base;
				td, th{
					border: none;
					&:focus,&:hover{
						background-color: transparent;
						//color:$brand-primary;
					}
					.focused{
						background-color: transparent;
						//color:$brand-primary;
					}

				}
			}
		}
		i{
			color:$icon_color;
			span{
				color:$gray-base;
			}
		}
		/* botón imprimir*/
		.document-action span, .document-action span:before {
			//color:$icon_color;
			background-color: transparent;
			border:0px;


		}
		.picto-print{
				color:$icon_color;
			 	line-height: 1em;
			 	    font-size: 2.0rem;
				 &:hover,&:focus{

				 }
			}
		.document-action a:hover i:before {
		    border-color: transparent;
		    color:$gray-darker;
		}




		.border-white{
			border: 1px solid $white;
		}
		.btn-link{
			//background-color: $brand-primary;
			color: $white;
			border-radius: 6%;
		    padding: 1.2rem;

		}


	#eventShare{
		background-color: transparent;
		//color: transparent;
		&:hover,&:focus{
			background-color: transparent;
		}
		}


		.datepicker-switch{
			text-align: center;
		}



	/* botón Veure més*/
		#home-events{
		 .get-more-events , .get-more-events:visited{
		 	border-radius: 5%;
		 	background-color: $brand_primary;
		 	padding:1.3rem;
		 	&:hover,&:focus{
		 		color:$brand_primary;
		 		background-color: $white;
		 	}
		 }
		}

		/* botón de afegir al calendari y events passats en actualitat/agneda*/
		.boton-rojo{
			font-size: 	    $font-size-base;
			font-style: 	none;
			background-color: 	$brand_primary;
			width: 	100%;
			a span{color: $white;}
			i{
				color:$icon_color;
			}
		}

		// mnp 2018_08_07
		.cal_date span{
			color:$gray-darker;
			&.cal-day{
				font-size: 	$font_day_calc;
			}

		}



	 // 3.5.1 datepicker - calendar
		 #datepicker{
			 table {
				 text-align: center;
			 }

		 	.datepicker-days{
		 		.day.active, .day.active.today, {

		 		    @extend .circle;
					}


		 	}

			.datepicker-days{
		 		.day.event {

		 		    @extend .circle_event;
					}


		 	}

			.circle_event {
				position: relative;
				z-index: 1;
				&:after {
					content: '';
					background-color: #FAB129;
					width: calc(1em + 1.2rem); height: calc(1em + 1.2rem);
					border-radius: 50%;
					position: absolute;
					left: 50%;top: 50%;
					transform: translate(-50%, -50%);
					z-index: -1;
				}
			}
		}
		/*MNp para evitar el rojo de fondo en td*/
		.datepicker table tr td, .datepicker table tr td.today.active, .datepicker table tr td.today:hover, datepicer table tr td.today:hover.active{
			background-color: transparent!important;
		}


	// 3.6 - Banners

	.footerBanners__list{
		//height: 100px!important;
	}
	.footerBanners__list div.owl-stage div.owl-item{
		height: 45vw;
		@media (min-width: $screen-sm-min) { // 768 pixels - 48em
			height: 11.2rem + 1.6rem;
		}
		@media (min-width: $screen-md-min) { // 992 pixels - 62em
			height: 14.9rem + 1.6rem;
		}
		@media (min-width: $screen-lg-min) { // 1200 pixels - 75em
			height: 13.5rem + 1.6rem;
		}
	}
		.banners {
			&__list {
				display: grid;
				grid-gap: 3.2rem;
				grid-template-columns: repeat(auto-fill, minmax(21.5rem, 1fr));
				&:after, &:before {
					content: none;
				}
			}

			&__link {
				border: .1rem solid $gray-light-medium;
				width: 100%; height: 100%;
				height: 15.0rem;
				overflow: hidden;
			}
		}
		#owlBannersContainer {
			button {
				background-color: transparent;
				padding: 0;
				span:before {
					margin:0;
				}
			}
		}



	//3.7 - Teme

		/*
		3.8 Xarxes sociales
		*/

		#button-update{
		 	border-radius: 5%;
		 	background-color: $brand_primary;
		 	padding:1.3rem;
		 	&:hover,&:focus{
		 		color: $white;
		 		background-color: $gray-base;
		 	}
		 }

	// 4 - FOOTER

		#portal-footer-wrapper {
			position: relative;

		}
		.logoFooter img {
			width: 20.0rem;
			height: auto;
			margin: 0 auto;
		}


	// 4.2 - Footer menu

		@media (min-width: $screen-sm-min) { //768px
			.footerMenu {
				ul {
					display: flex;
					justify-content: center;
				}
				li {
					& + li {
						margin-left: 1em; padding-left: 1em;
						border-left: 1px solid rgba($white, 0.75);
					}
				}
			}
		}
		@media (min-width: $screen-md-min) { //992px
			.groupActionsRRSS {
				display: flex;
				align-items: center;
				justify-content: center;
			}
			#footer-contact {
				display: flex;
				align-items: center;
				@media (min-width: $screen-lg-min) {
				border-left: .1rem solid $white;
				}
			}
		}

		//mnp 2018_07_30
		#portal-footer-wrapper {
			background-color: $back_color;

		}
		#footerNav ul{
			display:flex;
			justify-content: flex-end;
			li{
				padding-right:1.3rem;
			}
			a {
				color: $white;
				&:hover {
					color: $brand-primary;
				}
			}
			@media (max-width: $screen-sm-min) {
				flex-direction:column;
			}

		}
		#portal-info{
			@media (min-width: $screen-sm-min) { // 768 pixels - 48em
				border-left: 1px solid $white;
			}
		}

		#scrollup{
			display: block;
			width: 4.0rem;
			height: 4.0rem;
			position: fixed;
			bottom: 1.5rem;
			right: 1.5rem;
			padding: 5px;
			cursor: pointer;
			text-align: center;
			background-color: rgba($gray-darker,.75);
			color: $white;
			z-index: $zindex-navbar-fixed;
			border: .2rem solid $white;
			opacity: 0;
			transition: opacity $transition-fast ease;
			font-size: 1.6rem;
			&.showScroll {
				opacity: 1;
			}
			&:hover {
				background-color: rgba($white, 0.75);
				color: $brand-primary;
			}
		}



//Backdground

	#social-networks {
		background-color: #f1f1f1;
	}

  .socialButtons .social-feed-element {
		float: left;
		width: 32%;
		div {background: $white;
				margin:3px;}//mnp 2018_08_01
	}
	.socialButtons{
		width: 90%;
		margin: 0 auto;
	}
	.socialButtons > li {
		float:left;
		width:32%;
	}
	.socialButtons > li > ul {
		display: block;
		width: 100%;
	}
	.socialButtons > li > ul > li {
		display: block;
		float: none;
	}

//Gallery


//	5 - OTHER

/* varios mnp 2018_08*/

.circle {
	position: relative;
	z-index: 1;
	&:after {
		content: '';
		background-color: $brand-primary;
		width: calc(1em + 1.2rem); height: calc(1em + 1.2rem);
		border-radius: 50%;
		position: absolute;
		left: 50%;top: 50%;
		transform: translate(-50%, -50%);
		z-index: -1;
	}
}




 .text-underline{
 	text-decoration: underline;
 }
 .text-no-underline{
 	text-decoration: none;
 }
 .border-botom-color-icon1{
 	 border-bottom: 1px solid $icon_color;
 }

.border-right-white2{
	border-right: 2px solid $white;
}
	//	5.1 PORTLET

	.portlet .portletHeader {
		background: transparent;

		font-size:2.8rem;
		padding: 13px 1.5rem 1.3rem 0;
		color: $gray-darker;
		border:0px;
		text-align: right;
		font-weight: bolder;

	}

	.navTreeCurrentNode{
			color: $brand-primary;
			background-color: transparent;
			border:0px;
	}
	.portletNavigationTree .portletContent .navTreeCurrentNode>a {
     background:transparent;
     color: $brand-primary;
}
	.navTreeCurrentNode {
		background-color: $gray-lighter;
		color:$brand-primary;

	}

	.visualNoMarker {
		background-color: transparent;
	}
	.portletNavigationTree nav.portletContent>ul>li a{
		border:0;

	}
	.portletNavigationTree .portletContent > ul > li > a {
    display: block;
    background: transparent;
    }
	.navTreeCurrentNode>a {
		color:$brand_primary;

	}
	.portletNavigationTree{
		ul{
			list-style:none;
		}
	}
	.portlet .portletContent>ul>li a:before{
		content:none;
	}
	.portlet{
		border:0px;
		border-color: transparent;

	}

	.portlet .portletContent > ul > li a:before {
    content: "";
    position: absolute;
    font-size: 25px;
    margin-top: -9px;
    left: 15px;
    color:transparent;
}
.portlet .portletContent > ul li:hover a:before {
    color: transparent;
}
.portlet .portletContent > ul li:hover a:before {
     color: $brand_primary;
}
  //5.2 MODAL WINDOWS
  	.plone-modal-wrapper{
  		label{color:$brand_primary;}
  	}
  	#portal-column-one {
		border-right: .2rem solid $brand-secondary;
		border-width: 0 .2rem 0 0;
		padding-bottom: 7.0rem;
		margin-bottom: 7.0rem;
		.portlet {
			box-shadow: none;
			.portletContent {
				text-align: right;
				font-weight: $font-semi;
				a {
					color: $gray;
					&.navTreeCurrentItem {
						color: $brand-primary;
					}
				}
			}
		}
		a.navTreeFolderish {
			&:after {
				font-family: 'pictello';
				content: '\e809';
				display: inline-block;
				margin-left: 1.4rem;
				position: relative;
				margin-top: 0;
				right: auto;
				font-size: 1em;
			}
			&.navTreeCurrentNode:after {
				content:'\e80c';
			}
		}
		.navTreeLevel1 {
			background-color: $gray-lighter;
			font-weight: $font-regular;
			a {
				color: $gray-dark;
				&.navTreeFolderish:after {
					content: none;
				}
			}
		}
  	}



// 5.3 SOCIAL ICONS
  #document-actions{
  	.picto-facebook, .picto-twitter,.picto-gplus-1,.picto-whatsapp,.picto-mail{
		color: $icon_color;
		&:hover,&:focus{
			color:$white;
		}
	}


   .rounded-icon{
  	transition:0.3s;
  	background-color: 	$white;
  	color:$icon_color;
  	&:hover,&:focus {
  		 color: $white;
  		 background-color: $icon_color;
  		.span {
  			color:$icon_color;
  	   }
  	}
   }
}


  .document_action span{
  	color:$icon_color	;
  }

 @media (max-width: $screen-xs-max) {
  .socialButtons .social-feed-element {
    float: left;
    width: 100%;
}
}



//5.4 PAGINATION
.pagination ul>.active>span{

		background-color: $icon_color;
}
.pagination ul>li>a:hover,.pagination ul>li>a:focus{
	color: $icon_color;
	text-decoration: none !important;
	background-color: $white;
	background-color: $gray-lighter;
}
.pagination>.active>a{
	background-color: $brand-primary;
	border-color: $brand-primary;
}

.pat-structure .pagination>li>a, .pat-structure .pagination>li>span {

    color: $gray-darker;
}
.documentFirstHeading {
	font-weight: $font-regular;
	font-size: 4.7rem;
	margin-top: 0;
}

.pat-structure  a {
    color: $gray-darker;
    text-decoration: none;
}
.pat-structure .itemRow.folder .title a {
    color:$gray-darker;
}
// 5.5 SEARCH / BUSCADOR

#searchform {

	.input-group .form-control:first-child{
		border: 1px $gray-darker solid;

	}

	.input-group-btn{ //botón buscar
		padding-left: 3px!important;
		input.searchPage{
			border-color: 1px $gray-darker solid;
			background-color: $brand-primary;
			color:$white;
			border-radius: 2px;

		}
	}
}


//5.6 OTROS

#filters-map {
	z-index: 1000;
}

#portal-sitemap{
	a {

		&:hover {
			color: $brand-primary;
			text-decoration: none;
		}
	}
}
#portal-sitemap ul li:before {

         color: $brand-primary;

}
#relatedItemBox{
	a{color: $brand-primary;
		text-decoration: underline;}
}

#category{
	a.link-category:hover, a.link-category:focus {
	    color:$brand-primary;
	    text-decoration: none;
	    cursor: pointer;

	}
	display: flex;
    justify-content: flex-start;
}

.hiddenStructure{
	display: none!important;
}

div.modal-content a.selectIcon{
	color:transparent;
	text-decoration: none;
}

#edit-zone article a .selectIcon{
	color: transparent!important;
	text-decoration: none!important;
}
#edit-zone{
	article a{
		text-decoration: none!important;
	}
}
svg{
	text-decoration: none;
}

/// 6 - MINISITE
#fondo-descripcion{
	background-color: rgba($brand-primary, 0.75);
}

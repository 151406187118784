//
// Grid system
// --------------------------------------------------


// Equal height columns 
.eq-height {
    display: flex;
    flex-wrap: wrap;
    & > [class*='col-'], .eq-height article {
      display: flex;
      flex-direction: column;
  }
  .__center {
    justify-content: center;
  }
  &--middle {
    align-items: center;
  }
  &:before, &:after {
    content: none;
  }
}

// Distributed columns quantity independent
.distributed {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  justify-content: space-around;
  &--140 > div {
    flex: 1 0 14.0rem;
  }
  &--center {
    justify-content: center;
  }
}

/* clases para Flex*/
.displayFlex{
	display: flex;
}
.flexEnd{
	align-self:flex-end;
}
.flexStrech{
	align-items:stretch;
}
.flexDirectionColumn{
	flex-direction:column;
}
.flexDirectionRow{
	flex-direction:row;
}
.flex1{
	flex:1;
}
.flexAlignSelfEnd{
	align-self:flex-end;
}
 .justifySpace-between{
 	 justify-content: space-between;
 }

.no-after:after {
  content: none;
}

// Classes for better reading

 $con-tx: #FFFF00;
 $con-bg: #000080;
 $con-select: #CB0000;//$brand-primary;

 body.highContrast,  {
	background-color: $con-bg !important;
	color: $con-tx !important;
	a {
		border-color: transparent !important;
		box-shadow: none;
		text-decoration: underline !important;
		color: $con-tx !important;
		background-color: transparent!important;//mnp 2018_08_21
	}
	&.template-portada-view #content-header,
	.bg-white,
	.bg-primary,
	#agenda,
	.bg-black,
	.agenda-calendar,
	.agendaGroup,
	#mainMenuBar,
	#content-header,
	.bg-gray-lighter,
	.btn-primary,
	.social-feed-element,
	.alcaldeLink,
	.bg-gray-darker,
	.social-feed-element .media-body div,
	#ambits,#social-networks,
	#viewlet-above-content-blog-alcalde .plone-breadcrumb,
	.description,.event.summary,
	time, .cal_date span, 
	footer ,#breadcrumbs-current,
	.btn-rounded--red, .discreet,
	.pagination, .document-action,
	#header_fondo,.template-logged_out #content-core, .template-login_form #content-core,
	.pat-autotoc.autotabs, .autotabs {
		background: none $con-bg !important;
		color: $con-tx !important;
		background-color: transparent!important;
	}
	.text-white,
	.text-gray-light,
	.text-primary,
	.text-gray,
	.color-primary,
	.text-darker,
	#searchGadget,
	#portal-weather,
	.alcaldeLink__title,
	.standalone, .link-parent, [type="submit"], 
	button,
	#datepicker table thead th,
	#datepicker table,
	.searchPage .documentFirstHeading {
		color: $con-tx !important; 
	}
	#portal-announcements,
	.pat-autotoc.autotabs, 
	.autotabs,
	.pat-autotoc.autotabs .autotoc-nav, 
	.autotabs .autotoc-nav{
		background-color: $con-bg;
	}
	.bgHeader,
	//.portalSlider__image,
	.alcaldeLink__image,
	//.footerBanners__image,
	.social-feed-element .attachment,
	#interior-slider .interiorSlider__image,
	.discursos__bg {
		display: none !important;
	}
	.searchGroup,
	input[type="text"], 
	input[type="password"], 
	input[type="email"],
	textarea, 
	select,
	.footerBanners__figure,
	.btn-rounded--red,
	.owl-dot.active span,
	.owl-dot span {
		border-color: $con-tx !important;
	}
	.pat-livesearch .livesearch-results,
	input[type="text"], 
	input[type="password"],
	input[type="email"],
	textarea, 
	select,
	.standalone, .link-parent, [type="submit"], button,
	#interior-slider,
	#viewlet-above-content-blog-alcalde,
	.bg-gray-light-medium,
	.owl-dot span,
	.active .btnEvents, 
	.btnEvents:hover, 
	.btnEvents:focus,
	.btnEvents {
		background-color: $con-bg !important;
	}
	.btn,
	.btn-primary,
	.homeLinks__icon {
		border: .2rem solid $con-tx;
		background-color: $con-bg;
		color: $con-tx;
	}
	.homeLinks__icon, 
	#portal-weather,
	.footerBanners__figure {
		svg {
			fill: $con-tx !important;
		}
	}
	#content-header #header-logo {
		background-color: $white;
	}
	.social-feed-element .content,
	.footerBanners__link {
		background-color: transparent;
	}
	.miniSlider {
		&__text, &__more {
			background-color: $con-bg !important;
			color: $con-tx !important;
		}
	}
	.ambit__bg {
		display: none;
	}
	#viewlet-above-content-blog-alcalde {
		background-image: none !important;
	}
	.image-aemet,
	.owl-dot.active span {
		background-color: $con-bg;
		color:$con-tx;
		opacity: 1 !important;
	}

	.newsItem , .author-title{
		color:$con-tx;
	}

	.portletContent{
		background-color: $con-bg;
	}
	.navTreeItem{
		background-color: transparent;
		color: $con-tx!important;
		&:hover,&:focus{
			background-color: transparent;
			color:$con-select!important;
		}
	}
	.portletNavigationTree .portletContent > ul > li > a:hover,.portletNavigationTree .portletContent > ul > li > a:focus {
	    background: transparent;
	    color:$con-select!important;
		}
	.portlet .portletContent>ul li:hover ,.portlet .portletContent>ul li:focus{
    	background: transparent!important;
	}
	.proyecto{ 
		 opacity: 100!important;
		&::after{
		 content: "Semic - Ecityclick (https://www.ecityclic.com/ca)"!important;
		}
		span{
			display: none!important;
		}

	}


 /* para el gestor de contenido*/
 /* mnp 2018_11_06*/

    table, table span, .table.table-striped.table-bordered,.itemRow.state-published.type-Folder.folder,
    label .required:after, #edit-zone,
    .itemRow, .table.table-stripted, 
    .autotoc-nav,.autotoc-nav a,
    /* mensages del editor*/
    .portalMessage.warning > strong, .portalMessage.warning > dt,
    .portalMessage.info > strong, .portalMessage.info > dt ,


    .template-login_form #content-core, .template-logged_out #content-core,.mce-container-body,
     #login-form, legend, #content-core form, 
    .pat-formautofocus, #content-core, // el login 
    .pat-formautofocus .field input, #login-form input, #login-form label,
    .pat-formautofocus .field label,
    .btn-default:hover, .btn-default:focus, 
    .btn-default.focus, .btn-default:active, 
    .btn-default.active, .open>.dropdown-toggle.btn-default,
    .querystring-preview-description,.select2-container,
    
    .btn-default:focus, .pat-structure .btn-default.focus, 
    .pat-structure .btn-default:active, .pat-structure
    .btn-default.active, .open>.dropdown-toggle.pat-structure 
    .btn-default, .open>.pat-structure .dropdown-toggle.btn-default, 
  
    #form-buttons-save, .context:hover, .formControls input , #form-widgets-IShortName-id,
    .pattern-relateditems-path,// elementos relacionados 
    .portalMessage.info, .portalMessage.warning,.portalMessage.warning a, 
    .portalMessage.info > strong:before, .portalMessage.info > dt:before,
    
    /* combos */
    .select2-container, .select-2-container-multi,.select2-search-field, .select2-search-choice,
     li.select2-search-choice,
    .select2-search-choice,.select2-container-multi .select2-choices .select2-search-choice,
    .select2-input,.select2-search-choice div,ul.select2-choices div,
    
    /* portlets */
    .portletHeader,
    .portletNavigationTree nav.portletContent > ul > li strong,
    .portletNavigationTree.portletSiteSetup .inner-configlet:hover,
    #portlet-prefs ul ul li a:before ,

    .documentDescription,
    .label, .label.label-default,th, #btngroup-mainbuttons, #btngroup-mainbuttons .btn,
    ul, ul li, ul.select2-choices, ul.select2-choices, .select2-choices, .select2-chosen,ul li,
    ul.select2-choices li, ul.select2-choices li label,
    #portal-sitemap ul li::before , //puntos delante de los listados  
   
    /*fechas*/
    .picker__day--highlighted, 
    .picker__select--month, .picker__select--year, .picker__box,.picker__day, .picker__day--infocus,.pattern-pickadate-time .picker__input,
    .picker__nav--next,.picker__nav--prev,
     .historyRecord,
     
     //ventana modal
     .plone-modal-content,.plone-modal-header,.plone-modal-close,   
     .plone-modal-title,.plone-modal-footer, td.notDraggable,tr.dragable.odd,tr.draggable.odd td,
     .plone-modal .plone-modal-dialog .plone-modal-header .plone-modal-close ,
     .plone-modal-wrapper label ,
     .highlightedSearchTerm,
     #popover-workflow,.items.popover-content,.popover-title,.help-block,
     .items .popover-content,
     .state-published,
     input.form-control,
     .pattern-relateditems-result .pattern-relateditems-result-path,
     ul.draggable.odd, ul.draggable.odd li ,

     ul .pagination    , ul.pagination li, ul.pagination li a ,
     .configlets,.configlets li,
     .autotabs
    
    {     
        background-color: $con-bg !important;
        color: $con-tx !important;
    }

  .pat-structure .alert.status {   
      color: $con-tx  ;
  }

  .alert-warning {
      background-color: $con-bg;
      border-color: $con-tx ;
      color: $con-tx  ;
  }
  ::placeholder{
    color:$con-tx;
  }


  /* FECHAS */
  .picker__day--highlighted{text-decoration: underline;}    
  .picker__nav--next:before  {   
      border-left: .75em solid $con-tx !important;   
  }
  .picker__nav--prev:before,  {
  border-right: .75em solid $con-tx !important; 
  }
  .picker__button--close:before {    
      color: $con-tx!important;   
  }
  .picker__button--clear:before {    
    border-top: 3px solid $con-tx!important;   
  }
  .picker__button--today:before {  
    border-top: .66em solid !important;  
  
  }
  .picker__day--today:before {    
    border-top: .5em solid $con-tx!important;
  }




    // tabla de contenido
    .pat-structure{
        
          .table-bordered>tbody>tr:nth-of-type(odd),//filas pares
          .table-bordered>thead>tr>th, .pat-structure
          .table-bordered>tbody>tr>th, 
          .table-bordered>tfoot>tr>th, .pat-structure 
          .table-bordered>thead>tr>td, 
          .table-bordered>tbody>tr>td,
          .table-bordered>tfoot>tr>td,
          .open>.dropdown-toggle.btn-default,
          .pat-inlinevalidation,
          .btn-default:hover, 
          .table-bordered>tbody>tr>td,
          .dropdown-menu>li>a:hover, .pat-structure .dropdown-menu>li>a:focus,  // menú de opciones
          #btn-selected-items, #btn-selected-items .btn
          th ,//para el titulo de la tabla de contenidos
          #main-menu  // en dispositivos moviles sale fondo del $brand-primary, para que salga el fondo del $con-bg
          {
            background-color: $con-bg !important;
            color: $con-tx !important;
           }     
    }




    #edit-zone .plone-toolbar-logo:after {  //flecha debajo del logo de plone de la barra del editor
      border-top: 4px solid $con-tx;
   }

    .formControls{ // controles del formulario

      background-color: $con-bg !important;
      input{
        border: 1px solid $con-tx !important; // border de los botones guardar/cancelar
        background-color: $con-bg !important;
      }
    }
  
  .homeLinks__icon:before{// el circulo alrededord de los iconos
            background: linear-gradient(to bottom, $con-bg 0%, $con-tx 100%) !important;
  } 

  input[type="text"], input[type="password"], input[type="email"], button[type="button"],.textarea, select, // campos de formulario
  #portalLanguageGoogle .goog-te-combo,// combo del idioma de google
  .formHelp, // textos ayuda que no sean gris clarito 
  .option .label, // textos de los cuadros de chequeo  
  .glyphicon, span .glyphicon, a span .glyphicon, .btn a .glyphicon  // glyphicon iconos
  {
    color: $con-tx !important;
    background-color: $con-bg !important;
   }

    .mce-tinymce.mce-container.mce-panel,.mce-container { //editor de texto
        .mce-menubar .mce-menubtn button span,
        .mce-container-body, //fondo barra herramientas
        button,  // botones barra herramientas
        .mce-icon, i .mce-icon, button i .mce-ico,.mce-i-image:before, i .mce-i-image:before,  //los iconos de la barra de herramientas
        .mce-stack-layout, //como con opciones de la barra de herramientas
        .mce-menu-item,
        .mce-text, .mce-icon, i.mce-icon, button i.mce-ico *:before, 
        .mce-ico.mce-i-link:before, .mce-i-unlink:before,.mce-i-link:before, *:before,
        .mce-menu-item .mce-ico, .mce-menu-item .mce-text , .mce-menu-item, .mce-menu-item:hover, 
        .mce-wordcount,
        div,ul,li,.mce-i-selected,.mce-menu-item-sep
          {
        background-color: $con-bg !important;
        color: $con-tx !important;
       }
    }

   .glyphycon *:before{
     color: $con-tx!important;
   }

    .mce-caret {
      border-top: 4px solid $con-tx;    
    }

  // historicos
  #history-list{
      .listing > tbody > tr:nth-child(odd) > td, .listing > tbody > tr:nth-child(odd) > th,
    .odd,tr, td, hr,.state-private,span, .historic-action, .historyRecord
    {
        background-color: $con-bg !important;
          color: $con-tx !important;
    }

   }
   .diff_add, .diff_sub, .diff_chg{
      color:$con-bg; 
   }


   #mainMenu .parent > a > span:after { // triangulo debajo de las opciones padre del menú  
      border-color: rgba(666, 666, 666, 1) transparent transparent transparent !important;
      @media screen and (min-width: 75em){
        border-color: rgba(666, 666, 666, 1) transparent transparent transparent !important;
      }
  }


  /* SECCIONES PARA RODEARLAS POR UN BORDER Y QUE SE VEAN DIFERENCIALAS*/

  .pagination a, .pagination li a,
  .pat-structure .open>.dropdown-menu,
  .documentActions,
  #popover-workflow,#popover-rename,
  .footerBanners__item,
  .portletContent,
  .row.configlets,
  .autotoc-nav a,
  .portal-announcements,
  .querystring-criteria-index,
  .select2-container,.select2-chosen,
  .autotoc-level-1
  {
    border: 1px solid $con-tx!important;
  }



  #edit-zone nav>ul ul li:not(.plone-toolbar-submenu-header)>a:hover:before,
  .plone-toolbar-submenu-header {
      color:$con-tx!important;
  }

  #edit-zone nav>ul ul li:not(.plone-toolbar-submenu-header)>a:before {
      content: "•";  
      color: $con-tx!important;
     
  }

  .portletNavigationTree .portletContent a::after , .portletNavigationTree.portletSiteSetup a span {  
      color: $con-tx!important;    
  }

  //* color de fondo transparente para que no pise las lineas*/
  .label .required:after .autotoc-level-1, a .select2-chosen,
  .required.horizontal:after{
    background-color: transparent!important;
  }

  .highlightedSearchTerm{ // bara los elementos encontrados en el buscador
    background-color: transparent!important;
    border: 1px solid $con-tx;
  }


}